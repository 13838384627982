.type {
  font-size: 1.8rem;
}
.type :global(.title) {
  background-color: #fff;
  padding: 0.5em;
  font-weight: 700;
  color: var(--all);
}
.type :global(.title):hover,
.type :global(.title).hover {
  background-color: var(--all);
  color: #fff;
}
.type .qsResult {
  padding: 0.5em;
}
.type .qsResult:hover,
.type .qsResult.hover {
  background-color: var(--all);
  color: #fff;
}
