.container {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
}
.container .years {
  position: absolute;
  display: flex;
  flex: 1;
  height: 100%;
  width: 100%;
  flex-direction: row;
  padding: 4em 2em 0;
  overflow-y: hidden;
  overflow-x: auto;
}
