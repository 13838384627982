.container {
  font-size: inherit;
}
.container input {
  background-color: transparent;
  width: 100%;
  margin: 0.1em;
  padding: 0.2em 0.3em;
  color: inherit;
  font-size: inherit;
  border: none;
  outline: none;
}
.container input::placeholder {
  color: inherit;
  opacity: 0.24;
}
