* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;
}
html {
  font-size: 62.5%;
}
html,
body {
  height: 100%;
}
body {
  font-size: 1.5rem;
  -webkit-font-smoothing: subpixel-antialiased;
}
ul {
  list-style-type: none;
}
table {
  border: none !important;
  border-collapse: collapse;
}
table th,
table tr,
table td {
  border: none;
  padding: 0;
}
table tr {
  border-bottom: 1px solid $silver;
}
h1,
h2,
h3,
h4 {
  margin: 0;
  line-height: 1.2em;
  color: $baseColour;
}
h1 {
  font-size: 4.7rem;
}
h2 {
  font-size: 2.8rem;
}
img {
  display: block;
}
.hidden {
  display: none;
}
@-moz-keyframes hr {
  100% {
    -webkit-transform: rotate(720deg);
    -moz-transform: rotate(720deg);
    -ms-transform: rotate(720deg);
    -o-transform: rotate(720deg);
    -webkit-transform: rotate(720deg);
    -moz-transform: rotate(720deg);
    -ms-transform: rotate(720deg);
    -o-transform: rotate(720deg);
    transform: rotate(720deg);
  }
}
@-webkit-keyframes hr {
  100% {
    -webkit-transform: rotate(720deg);
    -moz-transform: rotate(720deg);
    -ms-transform: rotate(720deg);
    -o-transform: rotate(720deg);
    -webkit-transform: rotate(720deg);
    -moz-transform: rotate(720deg);
    -ms-transform: rotate(720deg);
    -o-transform: rotate(720deg);
    transform: rotate(720deg);
  }
}
@-o-keyframes hr {
  100% {
    -webkit-transform: rotate(720deg);
    -moz-transform: rotate(720deg);
    -ms-transform: rotate(720deg);
    -o-transform: rotate(720deg);
    -webkit-transform: rotate(720deg);
    -moz-transform: rotate(720deg);
    -ms-transform: rotate(720deg);
    -o-transform: rotate(720deg);
    transform: rotate(720deg);
  }
}
@keyframes hr {
  100% {
    -webkit-transform: rotate(720deg);
    -moz-transform: rotate(720deg);
    -ms-transform: rotate(720deg);
    -o-transform: rotate(720deg);
    -webkit-transform: rotate(720deg);
    -moz-transform: rotate(720deg);
    -ms-transform: rotate(720deg);
    -o-transform: rotate(720deg);
    transform: rotate(720deg);
  }
}
@-moz-keyframes min {
  100% {
    -webkit-transform: rotate(720deg);
    -moz-transform: rotate(720deg);
    -ms-transform: rotate(720deg);
    -o-transform: rotate(720deg);
    -webkit-transform: rotate(720deg);
    -moz-transform: rotate(720deg);
    -ms-transform: rotate(720deg);
    -o-transform: rotate(720deg);
    transform: rotate(720deg);
  }
}
@-webkit-keyframes min {
  100% {
    -webkit-transform: rotate(720deg);
    -moz-transform: rotate(720deg);
    -ms-transform: rotate(720deg);
    -o-transform: rotate(720deg);
    -webkit-transform: rotate(720deg);
    -moz-transform: rotate(720deg);
    -ms-transform: rotate(720deg);
    -o-transform: rotate(720deg);
    transform: rotate(720deg);
  }
}
@-o-keyframes min {
  100% {
    -webkit-transform: rotate(720deg);
    -moz-transform: rotate(720deg);
    -ms-transform: rotate(720deg);
    -o-transform: rotate(720deg);
    -webkit-transform: rotate(720deg);
    -moz-transform: rotate(720deg);
    -ms-transform: rotate(720deg);
    -o-transform: rotate(720deg);
    transform: rotate(720deg);
  }
}
@keyframes min {
  100% {
    -webkit-transform: rotate(720deg);
    -moz-transform: rotate(720deg);
    -ms-transform: rotate(720deg);
    -o-transform: rotate(720deg);
    -webkit-transform: rotate(720deg);
    -moz-transform: rotate(720deg);
    -ms-transform: rotate(720deg);
    -o-transform: rotate(720deg);
    transform: rotate(720deg);
  }
}
@-moz-keyframes loaderFade {
  0% {
    zoom: 1;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    filter: alpha(opacity=0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    -ms-opacity: 0;
    -o-opacity: 0;
    opacity: 0;
  }
  100% {
    zoom: 1;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    filter: alpha(opacity=100);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    -ms-opacity: 1;
    -o-opacity: 1;
    opacity: 1;
  }
}
@-webkit-keyframes loaderFade {
  0% {
    zoom: 1;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    filter: alpha(opacity=0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    -ms-opacity: 0;
    -o-opacity: 0;
    opacity: 0;
  }
  100% {
    zoom: 1;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    filter: alpha(opacity=100);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    -ms-opacity: 1;
    -o-opacity: 1;
    opacity: 1;
  }
}
@-o-keyframes loaderFade {
  0% {
    zoom: 1;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    filter: alpha(opacity=0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    -ms-opacity: 0;
    -o-opacity: 0;
    opacity: 0;
  }
  100% {
    zoom: 1;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    filter: alpha(opacity=100);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    -ms-opacity: 1;
    -o-opacity: 1;
    opacity: 1;
  }
}
@keyframes loaderFade {
  0% {
    zoom: 1;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    filter: alpha(opacity=0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    -ms-opacity: 0;
    -o-opacity: 0;
    opacity: 0;
  }
  100% {
    zoom: 1;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    filter: alpha(opacity=100);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    -ms-opacity: 1;
    -o-opacity: 1;
    opacity: 1;
  }
}
:root {
  --all: #ff1493;
  --sikart: #4682b4;
  --catalogues: #228b22;
  --archive: #ffd700;
  --grey: rgba(212,212,212,0.72);
}
#body {
  font-family: 'Helvetica', 'Arial', sans-serif;
  font-size: 1.5rem;
  font-weight: 100;
  letter-spacing: 0.02em;
}
#body a {
  text-decoration: none;
  color: inherit;
}
#body h1,
#body h2,
#body h3,
#body h4 {
  font-weight: 400;
}
#body h4 {
  font-size: 1.8rem;
}
#body #root {
  display: flex;
  width: 100%;
  height: 100%;
}
#body .btn {
  cursor: pointer;
  background-color: rgba(0,0,0,0);
}
#body .btn:hover {
  background-color: #999;
}
#body .btn.active {
  background-color: #999;
}
.BG-on-all {
  background-color: var(--all);
}
.BG-on-sikart {
  background-color: var(--sikart);
}
.BG-on-catalogues {
  background-color: var(--catalogues);
}
.BG-on-archive {
  background-color: var(--archive);
}
.BG-in-all {
  background-color: #fff;
}
.BG-in-sikart {
  background-color: #fff;
}
.BG-in-catalogues {
  background-color: #fff;
}
.BG-in-archive {
  background-color: #fff;
}
.border-on-all {
  border-color: #fff;
}
.border-on-sikart {
  border-color: #fff;
}
.border-on-catalogues {
  border-color: #fff;
}
.border-on-archive {
  border-color: #fff;
}
.border-in-all {
  border-color: var(--all);
}
.border-in-sikart {
  border-color: var(--sikart);
}
.border-in-catalogues {
  border-color: var(--catalogues);
}
.border-in-archive {
  border-color: var(--archive);
}
.color-on-all {
  color: #fff;
}
.color-on-sikart {
  color: #fff;
}
.color-on-catalogues {
  color: #fff;
}
.color-on-archive {
  color: #fff;
}
.color-in-all {
  color: var(--all);
}
.color-in-sikart {
  color: var(--sikart);
}
.color-in-catalogues {
  color: var(--catalogues);
}
.color-in-archive {
  color: var(--archive);
}
