.container {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.4em;
  font-size: inherit;
  border-width: 0.1em;
  border-style: solid;
  box-shadow: 0 1px 3px 1px rgba(0,0,0,0.24);
  cursor: pointer;
  user-select: none;
  transition: transform 0.2s;
  will-change: transform;
}
.container:hover {
  transform: scale(1.04);
}
.container:active {
  box-shadow: 0 1px 1px 1px rgba(0,0,0,0.12);
  transform: scale(1);
}
.container.round {
  border-radius: 50%;
}
