.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  background-color: transparent;
  border-color: transparent;
  font-size: 1.5rem;
  text-transform: uppercase;
  transition: background-color 0.6s;
  will-change: background-color;
}
.container.in {
  border-color: inherit;
  font-size: 1.1rem;
}
