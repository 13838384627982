.container {
  height: 96vh;
  padding: 24vh 6vw;
  transition: height 0.6s, padding 0.6s, font-size 0.6s;
  will-change: height, padding, font-size;
}
.container .header {
  display: flex;
  color: inherit;
}
.container .header .nav {
  padding: 0.4em 0 0.2em;
  margin: 0.2em 2em;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 100;
  letter-spacing: 0.06em;
  color: inherit;
  border-bottom: 2px solid transparent;
}
.container .header .nav:first-child {
  margin-right: auto;
}
.container .header .nav:global(.active),
.container .header .nav:hover {
  color: inherit;
  border-color: inherit;
}
.container .searchbar {
  height: 5.4em;
  font-size: 3rem;
  margin-top: 0.2em;
  transition: font-size 0.6s, color 0.6s, height 0.6s;
  will-change: font-size, color, height;
}
.container.in {
  height: 14em;
  padding: 1rem 9rem;
  font-size: 1.1rem;
}
.container.in .searchbar {
  font-size: 1.5rem;
}
