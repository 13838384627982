.container {
  flex: 1;
  min-width: 20vw;
  border-left-color: inherit;
  border-left-width: 1px;
  border-left-style: solid;
}
.container .year,
.container .content {
  margin: 0.4em;
}
.container .content .thumbnail {
  width: 50%;
}
.container .content .thumbnail img {
  width: 100%;
  height: auto;
}
