.container {
  display: flex;
  justify-content: space-between;
  width: 60vw;
  border-bottom-color: inherit;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.container .count div {
  display: inline-block;
}
.container .count .key {
  font-weight: 100;
  margin-right: 0.4em;
}
.container .count .val {
  font-weight: 700;
}
