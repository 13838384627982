.infoTrigger {
  position: absolute;
  top: 94vh;
  right: 2rem;
  color: #fff;
  font-size: 2.3rem;
  transition: top 0.8s;
  will-change: top;
  text-decoration: none;
}
.infoTrigger .infoButton {
  transition: border-color 0.8s, color 0.8s;
  will-change: border-color, color;
}
:global(.on) {
  color: #fff;
}
:global(.in) {
  color: #000;
}
:global(.in) .infoTrigger {
  position: absolute;
  top: 5rem;
  right: 2rem;
  font-size: 2rem;
}
