:global(#overlay) {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
:global(#overlay) .BG {
  background-color: rgba(0,0,0,0.24);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
:global(#overlay) .container {
  background-color: #fff;
  position: absolute;
  padding: 1em;
}
:global(#overlay) .container .close {
  position: absolute;
  top: 0.2em;
  right: 0.4em;
  font-size: 3rem;
}
