.container {
  flex-direction: column-reverse;
  flex: 1;
  transition: background-color 0.6s, color 0.6s;
  will-change: background-color, color;
}
.container:global(.on) {
  color: #fff;
}
.container:global(.in) {
  color: #000;
}
