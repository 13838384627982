.container {
  position: relative;
  width: 100%;
  height: 100%;
}
.container .loader,
.container .img {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  transition: opacity 1s ease;
  will-change: opacity;
}
.container .img {
  opacity: 0;
}
.container.cover .loader,
.container.cover .img {
  background-size: cover;
}
.container.loaded .loader {
  opacity: 0;
}
.container.loaded .img {
  opacity: 1;
}
