.container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background-color: inherit;
  position: absolute;
  right: 0;
  margin: -0.1em;
  border-color: inherit;
  border-style: solid;
  border-width: 0.1em;
  font-size: 2em;
  white-space: nowrap;
  box-shadow: 0 1px 6px 1px rgba(0,0,0,0.42);
}
.container .datapool {
  display: flex;
  flex: 1;
  align-items: center;
  margin: 0.2em 0 0.2em 0.3em;
  padding: 0.2em 0.3em;
  opacity: 0.24;
}
.container .datapool .label {
  will-change: font-size;
  transition: font-size 0.1s;
}
.container .datapool:hover,
.container .datapool:global(.active) {
  display: flex !important;
  color: inherit;
  opacity: 1;
}
.container .datapool:global(.active) {
  border-left-width: 0.04em;
  border-left-style: solid;
}
.container.chosen {
  background-color: transparent;
  top: 0;
  bottom: 0;
  border-color: transparent;
  box-shadow: none;
}
.container.chosen .datapool {
  display: none;
}
.container.chosen .datapool .label {
  font-size: 1.5rem;
}
