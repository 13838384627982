.goToTrigger {
  position: absolute;
  top: 94vh;
  left: 2rem;
  color: #fff;
  font-size: 2.3rem;
  transition: top 0.8s;
  will-change: top;
  text-decoration: none;
}
.goToTrigger .goToButton {
  transition: border-color 0.8s, color 0.8s;
  will-change: border-color, color;
}
.goToTrigger .goToButton .goToLabel {
  transition: transform 1.2s;
  will-change: transform;
}
:global(.in) .goToTrigger {
  position: absolute;
  top: 5rem;
  left: 2rem;
  font-size: 2rem;
}
:global(.in) .goToTrigger .goToButton .goToLabel {
  transform: rotate(180deg);
}
