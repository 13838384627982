.container {
  position: relative;
  height: 100%;
  background-color: inherit;
  margin-right: 0.1em;
}
.container .box {
  width: 100%;
  height: 100%;
  background-color: transparent;
  border-color: transparent;
  border-style: solid;
  border-width: 0;
  font-size: 2em;
}
.container .box :global(.type) {
  position: absolute;
  top: 0.4rem;
  left: 0.4rem;
  font-size: 1rem;
}
.container .box :global(.label) {
  font-size: 2rem;
}
.container.filled,
.container.focus {
  max-width: 32vh;
}
.container.filled .box {
  border-color: inherit;
  border-width: 0.04em;
}
.container.focus {
  width: 32vh;
}
.container.focus .box {
  background-color: inherit;
  position: absolute;
  height: auto;
  border-color: inherit;
  border-width: 0.1em;
  box-shadow: 0 1px 1px 1px rgba(0,0,0,0.12);
}
.container.empty {
  flex: 1;
}
